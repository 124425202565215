<template>

  <div class="H5LinkSet">
    <a-spin :spinning="spinning">
    <div>
      <a-button type="primary" @click="addOpen">新增</a-button>
    </div>
    <a-table
      :columns="tableColumns"
      :data-source="tableData"
      style="margin-top: 15px"
      :row-key="(record) => record.id"
      :pagination="false"
    >
      <template slot="operation" slot-scope="text,item,index">
        <a @click="editOpen(item.id)">修改</a>
        <a-popconfirm
            title="是否删除？"
            ok-text="是"
            cancel-text="否"
            @confirm="tableDataRowDelete(item.id)"
        >
          <a style="margin-left: 20px">删除</a>
        </a-popconfirm>
      </template>
    </a-table>

<!--------------------------------------新增/修改 弹出框区域-------------------------------------->
    <a-modal
        title="新增"
        :visible="addVisible"
        @ok="addSubmit"
        @cancel="addClose"
    >
      <a-form-model
          :model="addForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="formRules"
          ref="addForm"
          :pagination = "false"
      >
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="addForm.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="code" prop="code">
          <a-input v-model="addForm.code" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="地址" prop="url">
          <a-textarea v-model="addForm.url" placeholder="请输入"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
<!--修改-->
    <a-modal
        title="修改"
        :visible="editVisible"
        @ok="editSubmit"
        @cancel="editClose"
    >
      <a-form-model
          :model="editForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="formRules"
          ref="editForm"
      >
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="editForm.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="code" prop="code">
          <a-input v-model="editForm.code" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="地址" prop="url">
          <a-textarea v-model="editForm.url" placeholder="请输入"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    </a-spin>
  </div>

</template>
<script>
import {getH5Link, postH5LinkAdd, putH5LinkDelete, putH5LinkUpdate} from "@/service/systemApi";

export default {
  name: 'H5LinkSet',
  data () {
    return {
      tableColumns: [
        {
          title: '名称',
          width: '30%',
          dataIndex: 'name'
        },
        {
          title: 'code',
          width: '20%',
          dataIndex: 'code'
        },
        {
          title: '地址',
          width: '40%',
          dataIndex: 'url'
        },
        {
          title: '操作',
          fixed: 'right',
          width: 150,
          scopedSlots: {customRender: 'operation'}
        }
      ],
      tableData: [],
      spinning: false,
      addVisible: false,
      editVisible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      addForm: {},
      editForm: {},
      formRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        url: [{ required: true, message: '请输入链接', trigger: 'blur' }],
        code: [{ required: true, message: '请输入code', trigger: 'blur' }],
      }
    }// data return
  },
  created() {
    this.getH5TableData()
  },
  methods: {
    async getH5TableData () { // 获取 table 列表
      this.spinning = true
      const response = await getH5Link('')
      if (response.code === 0) {
        this.tableData = response.data
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    addClose () {
      this.addVisible = false
    },
    addOpen () {
      this.addVisible = true
    },
    async addSubmit () { // 新增提交
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          this.spinning = true
          let temp = window.localStorage.getItem("user_info") // 获取当前登录账户 id
          let data = {
            "code": this.addForm.code,
            "name": this.addForm.name,
            "url": this.addForm.url,
            "createdBy": JSON.parse(temp).id // 将获取的 id 值进行 json 解构
          }
          const response = await postH5LinkAdd(data)
          if (response.code === 0) {
            this.$message.success("新增成功")
            this.addVisible = false
            this.addForm = {}
            await this.getH5TableData()
          }else {
            this.$message.warning(response.message)
          }
          this.spinning = false
        }else {
          this.$message.error("请填写全部表单")
        }
      })

    },
    editClose () {
      this.editVisible = false
    },
    async editOpen (rowId) { // 修改打开
      this.spinning = true
      const response = await getH5Link(rowId)
      if (response.code === 0) {
        this.editForm = response.data[0]
        this.editVisible = true
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async tableDataRowDelete (rowId) { // 删除方法
      this.spinning = true
      const response = await putH5LinkDelete(rowId)
      if (response.code === 0) {
        this.$message.success("删除成功")
        await this.getH5TableData()
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async editSubmit () { // 修改提交
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          this.spinning = true
          let data = {
            "code": this.editForm.code,
            "name": this.editForm.name,
            "url": this.editForm.url
          }
          const response = await putH5LinkUpdate(this.editForm.id, data)
          if (response.code === 0) {
            this.$message.success("修改成功")
            this.editVisible = false
            await this.getH5TableData()
          }else {
            this.$message.warning(response.message)
          }
          this.spinning = false
        }else {
          this.$message.error("请填写全部表单")
        }
      })

    }
  }
}
</script>

<style lang="scss" scoped>

</style>